import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import Auth from 'components/Auth';
import Layout from 'components/Order/Layout';
import Select from 'components/UI/Select';
import CcAuth from 'components/CcAuth';
import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { useLoading } from 'utils/loading';
import { PATH_ORDER } from 'utils/constants/paths.js';
import TitleName from 'components/UI/Header/TitleName';

const StyledLayout = styled(Layout)``;

const Container = styled.div``;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  @media (max-width: ${md}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 108px;
    background-color: #ffffff;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    z-index: 1000;
    padding: 24px 15px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const BtnGroup = styled.div`
  display: flex;
  gap: 0 8px;
`;

const Btn = styled.div`
  width: 114px;
  height: 38px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    width: 343px;
    height: 60px;
    background-color: #5fd2da;
    border: 1px solid #5fd2da;
    border-radius: 40px;
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
  }

  ${(props) =>
    props.primary &&
    css`
      background-color: #eafeff;
      border: 1px solid #5fd2da;
      border-radius: 30px;
    `}
`;

const Card = styled.div`
  height: 717px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;

  @media (max-width: ${md}) {
    padding: 16px;
    height: auto;
  }
`;

const OrderNum = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 12px;

  @media (max-width: ${md}) {
    margin-bottom: 8px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  height: 70px;

  @media (max-width: ${md}) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
`;

const RowLabel = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  margin-right: 83px;

  @media (max-width: ${md}) {
    margin-right: 0px;
    margin-bottom: 10px;
  }
`;

const RowInputWrapper = styled.div`
  width: 343px;

  @media (max-width: ${md}) {
    width: 100%;
  }
`;

const CancelBtn = styled.div`
  width: 64px;
  height: 46px;
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 30px;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #5fd2da;
  order: 3;
  margin-left: auto;

  @media (max-width: ${md}) {
    display: flex;
  }
`;

const RefundCancel = (props) => {
  const pageTitle = '訂單明細';
  const api = useAPI();
  const loading = useLoading();

  const orderNo = props.location.state ? props.location.state.orderNo : '';
  const [ccAuthIsReady, setCcAuthIsReady] = useState(false);
  const [cancelOption, setCancelOption] = useState();
  const [selectedObject, setSelectObject] = useState();

  const fetchCancelOption = () => {
    loading.show();
    api
      .getCustomerCancel()
      .then((res) => {
        if (res.length > 0) {
          const optionArr = [];
          for (const options of res) {
            optionArr.push({
              value: options.value,
              label: options.name,
            });
          }
          setCancelOption(optionArr);
        }
        loading.dismiss();
      })
      .catch((error) => {
        loading.dismiss();
      });
  };

  const cancel = () => {
    if (!selectedObject) {
      alert('請選擇取消原因');
      return;
    }
    api
      .orderCancel(orderNo, selectedObject?.label)
      .then((res) => {
        if (res.code !== '200') {
          alert(res.reason, () => {
            navigate(PATH_ORDER);
          });
          return;
        }
        alert('訂單已取消', () => {
          navigate(PATH_ORDER);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!ccAuthIsReady) return;
    fetchCancelOption();
  }, [ccAuthIsReady]);

  return (
    <>
      <CcAuth onFinished={() => setCcAuthIsReady(true)} />
      <TitleName />
      {ccAuthIsReady && (
        <StyledLayout
          {...props}
          pageTitle={pageTitle}
          extraBtn={
            <CancelBtn onClick={() => window.history.back()}>取消</CancelBtn>
          }
        >
          <Auth />
          <Container>
            <TitleBar>
              <Title>{pageTitle}</Title>
              <BtnGroup>
                <Btn onClick={() => cancel()}>確定送出</Btn>
              </BtnGroup>
            </TitleBar>
            <Card>
              <OrderNum>訂單號：{orderNo}</OrderNum>
              <Row>
                <RowLabel>退訂原因</RowLabel>
                <RowInputWrapper>
                  <Select
                    onChange={(e) => setSelectObject(e)}
                    options={cancelOption}
                    value={selectedObject}
                    isSearchable={false}
                    placeholder="請選擇退訂原因"
                  />
                </RowInputWrapper>
              </Row>
            </Card>
          </Container>
        </StyledLayout>
      )}
    </>
  );
};

export default RefundCancel;
